import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useStyles } from "./Privacy.styles";

export function PrivacyContent() {
	const classes = useStyles()
	return (
		<Grid container className={classes.root}>
			<Grid item container>
				<Typography color="primary" className={classes.title}>Aviso de privacidad</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}> Políticas de Privacidad: </Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}>
					FINANCIERA INTELIGENTE, S.A. DE C.V., SOFOM, E.N.R., en adelante “LA SOFOM”, responsable de los datos personales, utilizará la información que proporcione el Usuario, titular de los datos personales, únicamente por sí misma y para los fines propios establecidos en el contrato y sus anexos. LA SOFOM podrá divulgar la información personal del Usuario si existe requerimiento de autoridad que obligue a LA SOFOM a revelarle tal información; aunado a lo anterior, LA SOFOM podrá utilizar la información del Usuario en caso de que sea necesario para la protección de sus derechos. El Usuario, al suscribirse al sitio www.kredi.mx y proporcionar los datos para obtener información, acepta sujetarse a las presentes Políticas de Privacidad.
				</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Aviso de Privacidad:</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}> En cumplimiento con la Ley Federal de Protección de datos personales en posesión de los particulares, en lo sucesivo “Ley”, así como con el Reglamento de la Ley Federal de Protección de datos personales en posesión de los particulares, en lo adelante “Reglamento”, y, Lineamientos del Aviso de Privacidad, en lo futuro “Lineamientos”, LA SOFOM, con domicilio para oír y recibir notificaciones el ubicado en Av. Vasconcelos #150 Local 10 Interior A , Col. Del Valle en San Pedro Garza García, Nuevo León, así como sus sucursales filiales; es responsable del tratamiento de los datos personales que usted proporcione y de su protección, en términos y de conformidad con los principios previstos en “La Ley”.</Typography>
				<Typography className={classes.text}>Para LA SOFOM es esencial proteger a sus Usuarios, es por ello que aplica lineamientos, políticas y procedimientos para proteger su información. Por lo anterior, las Políticas de Privacidad tienen como fin informar el tipo de información que se recaba respecto del Usuario, la forma en que dicha información es manejada y, compartida. El Usuario de LA SOFOM deberá estar informado de que sus datos personales estarán protegidos. La seguridad de su información personal es prioridad, por ello se protege mediante el mantenimiento de protecciones físicas, electrónicas y de procedimiento, capacitando a empleados en el manejo adecuado de información personal, y con el fin de impedir que terceros no autorizados accedan a la misma. La Ley, contiene todas las disposiciones que se cumplimentan para proteger los datos personales. El Usuario puede acceder al contenido de la Ley a través de los portales que el Gobierno Federal, por conducto de la Secretaría de Gobernación, y la Cámara de Diputados del H. Congreso de la Unión tienen en Internet y cuyas direcciones son: <a target={"_blank"} href="http://www.ordenjuridico.gob.mx" >http://www.ordenjuridico.gob.mx</a> y <a target={"_blank"} href="http://www.diputados.gob.mx"> http://www.diputados.gob.mx. </a> En virtud de lo antes expuesto, se informa que en cumplimiento de las Políticas de Privacidad internas y de las disposiciones legales aplicables, los datos personales que se obtienen, en virtud de las operaciones que el Usuario solicite o celebre con LA SOFOM, serán tratados de manera confidencial a través de los sistemas establecidos para dichos efectos.</Typography>
				<Typography className={classes.text}>Los datos personales que usted nos proporcione podrán incluir de manera enunciativa, más no limitativa, lo siguiente:</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Datos Personales Recabados:</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}>LA SOFOM recabará datos de identificación (ej. Correo electrónico, número celular y dirección Protocolo de Internet, conocida como IP), médicos, laborales, patrimoniales, crediticios y financieros (tales como estados de cuenta bancarios, gastos en tarjetas de crédito) necesarios para el análisis y probable formalización del crédito que el Usuario solicite o requiera; de manera enunciativa, más no limitativa, LA SOFOM podrá recabar su nombre; domicilio; fecha de nacimiento; lugar de nacimiento; nacionalidad; antecedentes médicos; ocupación, profesión, actividad o giro del negocio al que se dedique, números telefónicos, Clave Única de Registro de Población; clave del Registro Federal de Contribuyentes. LA SOFOM recabará, adicionalmente, los datos necesarios para cumplir con las disposiciones legales aplicables en materia de prevención de Lavado de Dinero; asimismo, LA SOFOM podrá requerir datos adicionales que permitan estimar la viabilidad del pago de crédito, valiéndose para ello de un análisis que parte de información que permite establecer la solvencia crediticia y capacidad de pago del Usuario.</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Finalidad del Tratamiento de Datos Personales:</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}>
					Los datos personales que se recaben serán utilizados para la operación  de los créditos que el Usuario hubiere solicitado y probablemente contratado, pudiendo ser utilizados para los fines que, de manera enunciativa mas no limitativa, se describen a continuación: a) Confirmar la identidad de sus Usuarios; b) Verificar el historial crediticio de sus Usuarios; c) Prevención contra el Lavado de Dinero y la comisión de actos ilícitos en general; d) Para cobranza de los créditos con los Usuarios; e) Para mercadotecnia, publicidad y promoción de los servicios ofrecidos por LA SOFOM; f) Para estadística; y g) En general para cumplir con los procesos y documentos relacionados con el otorgamiento de créditos por parte de LA SOFOM.
				</Typography>
				<Typography className={classes.text}>Tratamientos para fines secundarios o accesorios. Los Usuarios manifiestan que LA SOFOM podrá utilizar los datos con fines de mercadotecnia y publicidad.</Typography>

			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Transferencia de Datos Personales:</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}>
					LA SOFOM podrá transferir los datos personales del Usuario a subsidiarias, empresas relacionadas, proveedores, inversionistas o instituciones de crédito relacionadas con el otorgamiento del crédito, terceros, mexicanos o extranjeros, que le provean de servicios necesarios para su debida operación para las finalidades establecidas en el presente Aviso de Privacidad, así como para poder cumplir con las obligaciones legales frente al Usuario y frente a las autoridades de los países en los que opera. En dichos supuestos, se informa que LA SOFOM compartirá con las personas que tengan acceso a sus datos personales este Aviso de Privacidad a fin de que cumplan con la política de Confidencialidad y de Privacidad de Datos de LA SOFOM, así como con los principios de protección de datos personales establecidos en la Ley.
				</Typography>
				<Typography className={classes.text}>Las transferencias de datos personales de identificación y/o laborales y/o patrimoniales y/o financieros citadas anteriormente no requieren de su consentimiento. En consecuencia, cualquier otra finalidad no mencionada anteriormente requerirá consentimiento expreso del Usuario.</Typography>

			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Consentimiento para Divulgación de Datos Personales:</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}>
					Los Usuarios de LA SOFOM, en cualquier momento, pueden solicitar que LA SOFOM cese el uso y manejo de su información. LA SOFOM se compromete a atender su solicitud lo más pronto posible; sin embargo, dependiendo de las circunstancias de cada caso y de los servicios que se brindan a cada crédito en particular, el proceso podrá demorarse. A fin de que el Usuario pueda revocar el consentimiento para la divulgación de sus datos personales, podrá enviar un correo electrónico, llamar o asistir a la Unidad Especializada de Atención a Usuarios de LA SOFOM para mayor información.
				</Typography>
				<Typography className={classes.text}>
					O bien, el Usuario puede acudir al Registro Público de Usuarios, en adelante REUS, de la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros, en adelante CONDUSEF, para limitar el uso y divulgación de sus datos personales, o acudir a la CONDUSEF donde puede obtener mayor información.
				</Typography>

			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Uso de cookies, web beacons y otras tecnologías de rastreo.</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}>
					A SOFOM utiliza cookies para facilitar la navegación en el sitio web www.kredi.mx. Los servicios electrónicos brindados por el responsable permiten recopilar, analizar y conservar información técnica relacionada con los hábitos de uso de los mismos, a través de cookies que recaban información de forma automática en el momento en que el Usuario hace uso de nuestros servicios electrónicos, si usted navega en el sitio web de LA SOFOM sin cambiar la configuración, se considerará que acepta su instalación y uso conforme a nuestra política de cookies. Sin embargo, si lo desea, puede cambiar la configuración de cookies en cualquier momento; para obtener información más detallada acerca de las cookies y de los medios disponibles para deshabilitar su instalación, recomendamos visitar la página web www.kredi.mx.
				</Typography>
				<Typography className={classes.text}>
					Para los datos personales que el Usuario proporcione a LA SOFOM a través de medios electrónicos como: el sitio web, mail, redes sociales, mensajes de WhatsApp; el titular de los datos personales entiende, acepta y reconoce que es posible que los sistemas de LA SOFOM recopilen datos como tipo de navegador, sistema operativo, páginas de internet visitadas, dirección de IP, etc., a través de “cookies” o “web beacons”.
				</Typography>
				<Typography className={classes.text}>
					Las finalidades del tratamiento de los datos obtenidos a través de estas tecnologías son:
					Marketing, publicidad, comportamiento de usuarios, determinar la capacidad de pago o riesgo crediticio
				</Typography>
				<Typography className={classes.text}>
					No serán transferidos los datos personales que se obtienen a través de estas tecnologías.
				</Typography>
				<Typography className={classes.text}>
					LA SOFOM responderá a través de correo electrónico, llamada o cualquier medio digital a todas las solicitudes, dudas, aclaraciones, comentarios y quejas que se reciban por los medios electrónicos descritos y bajo ningún motivo se le solicitará el envío de datos patrimoniales, económicos o sensibles a través de este medio, salvo mandato judicial de autoridad competente. La configuración de su equipo puede aceptar cookies automáticamente, pero si lo prefiere, para deshabilitar estas herramientas informáticas puede modificar la configuración de su equipo para rechazar los cookies. No será posible que LA SOFOM almacene sus preferencias en el sitio web.
				</Typography>

			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Modificaciones al Aviso de Privacidad:</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}>
					Cualquier modificación al presente aviso será notificada a través de cualquiera de los siguientes medios: un comunicado por escrito enviado a su domicilio o entregado en LA SOFOM; un mensaje contenido en su estado de cuenta; un mensaje enviado a su correo electrónico o a su teléfono móvil; un mensaje dado a conocer a través de www.kredi.mx  o de cualquier medio electrónico que utilice para celebrar operaciones con LA SOFOM.
				</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Unidad Especializada de Atención a Usuarios de LA SOFOM:</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}>
					En el domicilio aquí manifestado se encuentra la Unidad Especializada de Consultas y Reclamaciones (UNE) mediante el cual el Usuario podrá solicitar aclaraciones, procedimientos de revocación, ejercer los derechos ARCO u otros. El teléfono y correo electrónico del centro de atención a usuarios es consultas@kredi.mx con domicilio en: Av. Vasconcelos #150 Local 10 Interior A , Col. Del Valle en San Pedro Garza García, Nuevo León. Teléfono: 81 3231 2889.

				</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>Derechos ARCO y procedimientos de revocación:</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.text}>
					Usted o su representante legal debidamente acreditado podrán limitar el uso o divulgación de sus datos personales y podrá ejercer, cuando procedan, los derechos de acceso, rectificación, cancelación u oposición que la Ley prevé mediante solicitud presentada en el domicilio arriba señalado y previa acreditación de la personalidad con la que se ostenten mediante la presentación de la identificación o poder, acompañando a la solicitud por escrito la documentación soporte que considera pertinente para ejercer el o los derechos mencionados. LA SOFOM tiene un plazo de 60 días para dar respuesta a través del mismo medio por el que se recibió la solicitud. El ejercicio de cualquiera de dichos derechos no es requisito previo ni impide el ejercicio de otro. Así también, se informa que el Usuario tiene derecho a iniciar un Procedimiento de Protección de Datos ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales dentro de los 15 días siguientes a la fecha en que reciba la respuesta de LA SOFOM o a partir de que concluya el plazo de 20 días contados a partir de la fecha de recepción de su solicitud de ejercicio de derechos, en donde puede obtener mayor información.
				</Typography>
			</Grid>
		</Grid>
	)
}