import { PrivacyContent } from "../components/Privacy";
import { Layout } from "../layout";
import React from "react";
function TermsAndConditions({ location }: any) {
	return (
		<Layout location={location} title="Kredi | Aviso de privacidad" >
			<PrivacyContent />
		</Layout>
	)
}

export default TermsAndConditions